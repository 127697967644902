(function() { 'use strict';
  // Const
  // -----
  window.SMALL_MOBILE_WIDTH = 360;
  window.PORTRAIT_MOBILE_WIDTH = 480;
  window.LARGE_PORTRAIT_MOBILE_WIDTH = 700;
  window.LANDSCAPE_MOBILE_WIDTH = 800;
  window.PORTRAIT_TABLET_WIDTH = 768;
  window.LANDSCAPE_SMALL_TABLET_WIDTH = 900;
  window.LANDSCAPE_TABLET_WIDTH = 980;
  window.DESKTOP_WIDTH = 1024;
  window.DESKTOP_WIDE_WIDTH = 1440;

  // Selectors
  // ---------
  window.$WINDOW = $(window);
  window.$DOCUMENT = $(document);
  window.$HTML = $(document.documentElement);
  window.$BODY = $(document.body);
  window.$HEADER = $('.header');
  window.$INFO_MENU = $('.header-info-menu > ul');
  window.$SERVICE_MENU = $('.header-menu-container > .wrapper > ul');
  window.$MENU_CHECKBOX = $('.header-menu-checkbox');

  // Helpers
  // -------
  $WINDOW
    .on('calcSizes', () => {
      window.VIEWPORT_WIDTH = document.documentElement.clientWidth || Math.round(window.visualViewport.width) || $WINDOW.width();
      window.WINDOW_HEIGHT = $WINDOW.height();
      window.HEADER_HEIGHT = $HEADER.height();
    })
    .on('resize', () => {
      $WINDOW.trigger('calcSizes');
    })
    .trigger('calcSizes');

  /**
   * @return boolean
   */
  window.IS_DESKTOP_WIDE_WIDTH = () => VIEWPORT_WIDTH > DESKTOP_WIDE_WIDTH;
  /**
   * @return boolean
   */
  window.IS_DESKTOP_WIDTH = () => VIEWPORT_WIDTH >= DESKTOP_WIDTH;
  /**
   * @return boolean
   */
  window.IS_TABLET_WIDTH = () => (VIEWPORT_WIDTH >= PORTRAIT_TABLET_WIDTH && VIEWPORT_WIDTH < DESKTOP_WIDTH);
  /**
   * @return boolean
   */
  window.IS_WIDTH_LESS_PORTRAIT_TABLET = () => VIEWPORT_WIDTH < PORTRAIT_TABLET_WIDTH;
  /**
   * @return boolean
   */
  window.IS_LANDSCAPE_TABLET_WIDTH = () => VIEWPORT_WIDTH < LANDSCAPE_TABLET_WIDTH;
  /**
   * @return boolean
   */
  window.IS_LANDSCAPE_SMALL_TABLET_WIDTH = () => VIEWPORT_WIDTH < LANDSCAPE_SMALL_TABLET_WIDTH;
  /**
   * @return boolean
   */
  window.IS_MOBILE_WIDTH = () => VIEWPORT_WIDTH < LANDSCAPE_MOBILE_WIDTH;
  /**
   * @return boolean
   */
  window.IS_LARGE_PORTRAIT_MOBILE_WIDTH = () => VIEWPORT_WIDTH < LARGE_PORTRAIT_MOBILE_WIDTH;
  /**
   * @return boolean
   */
  window.IS_PORTRAIT_MOBILE_WIDTH = () => VIEWPORT_WIDTH < PORTRAIT_MOBILE_WIDTH;
  /**
   * @return boolean
   */
  window.IS_SMALL_MOBILE_WIDTH = () => VIEWPORT_WIDTH < SMALL_MOBILE_WIDTH;


  // Defaults
  // --------
  var cities = {},
      slickSettings = {
        autoplay: true,
        autoplaySpeed: 8000,
        prevArrow: '<div class="slick-prev"></div>',
        nextArrow: '<div class="slick-next"></div>',
        pauseOnDotsHover: true,
        customPaging: function() { return $('<div />'); },
        draggable: false
      },
      unveilSettings = {
        attribute: 'lazySrc',
        placeholder: '',
        offset: 50,
        throttle: 200
      };


  // Masked input
  // ------------
  $('input[type="date"]').attr('type', 'text');

  Inputmask.extendAliases({
    'phone-mask': {
      regex: '(\\+7|8) \\([0-689]\\d{2}\\) \\d{3}(\\-\\d{2}){2}',
      placeholder: '+7 (___) ___-__-__',
      keepStatic: true
    },
    'number-mask': {
      mask: '9{*}',
      greedy: false
    },
    'float-mask': {
      mask: '9{*}[(.|,)9{1,2}]',
      greedy: false
    },
    'email-mask': {
      mask: '*{1,64}[.*{1,64}][.*{1,64}][.*{1,63}]@-{1,63}.-{1,63}[.-{1,63}][.-{1,63}]',
      casing: 'lower',
      greedy: false,
      inputmode: 'email',
      quantifier: 5,
      skipOptionalPartCharacter: '',
      definitions: {
        '*': {
          validator: '[0-9\uff11-\uff19A-Za-z\u0410-\u044f\u0401\u0451\xc0-\xff\xb5!#$%&\'*+/=?^_`{|}~-]'
        },
        '-': {
          validator: '[0-9A-Za-z-]'
        }
      },
      onBeforePaste: e => e.toLowerCase().replace('mailto:', ''),
      onUnMask: e => e
    }
  });
  // Phone
  $('input[type="tel"], input[autocomplete="tel"]').inputmask('phone-mask');

  // Email
  $('.js-email-mask').inputmask('email-mask');

  // Number
  $('.js-number-mask').inputmask('number-mask');

  // Float number
  $('.js-float-mask').inputmask('float-mask');


  $DOCUMENT
    .ready(function() {
      $DOCUMENT
        .trigger('moveHeaderMenuItems')
        .trigger('headerServiceMenuEvents')
        .trigger('specsFormTextareaAutoHeight', [$('.specification-form .form-row-textarea .form-field .input-container textarea')]);
      $WINDOW.on('resize', function() {
        $DOCUMENT
          .trigger('moveHeaderMenuItems')
          .trigger('headerServiceMenuEvents')
          .trigger('specsFormTextareaAutoHeight', [$('.specification-form .form-row-textarea .form-field .input-container textarea')]);
      });

      // Header menu button
      // ------------------
      $MENU_CHECKBOX
        .on('change', e => {
          e.currentTarget.checked ? $BODY.addClass('menu-is-opened') : $BODY.removeClass('menu-is-opened');
        })
        .prop({'checked': false, 'disabled': false})
        .trigger('change');


      // Lazy load
      // ---------
      $('.header-slide-block > div > div:first-child').unveil($.extend({}, unveilSettings, {
        attribute: 'lazySrcSm',
        offset: 0,
        breakpoints: [
          {
            minWidth: PORTRAIT_TABLET_WIDTH,
            attribute: 'lazySrcMd'
          },
          {
            minWidth: LANDSCAPE_SMALL_TABLET_WIDTH,
            attribute: 'lazySrcLg'
          }
        ]
      }));

      $('.js-lazy-load, .list-view-item-image [data-lazy-src], .gallery-item img, .video-frame > a')
        .add($('.object-tour-block, .price-economy-block').data('lazySrc', '/i/object-tour-bg.jpg'))
        .add($('.object-inspection-block').data('lazySrc', '/i/object-inspection-bg.jpg'))
        .unveil(unveilSettings);



      // Design contents rotator
      // -----------------------
      $('.design-contents-rotator > div:first-child').each(function() {
        var $rotator = $(this);

        $rotator
          .on('init', function(e, slider) {
            slider.$slideTrack.find('.slick-cloned > div:first-child > div a').removeAttr('data-fancybox');
          })
          .on('beforeChange', function(e, slider, currentSlide, nextSlide) {
            if( !IS_MOBILE_WIDTH() && !slider.$slideTrack.hasClass('height-set') )
                slider.$slideTrack.css('height', slider.$slideTrack.height()).addClass('height-set');

            slider.$slides.eq(nextSlide).find('img:not(.loaded)').each(function() {
              $(this).attr('src', $(this).data('src')).addClass('loaded');
            });
          })
          .slick($.extend({}, slickSettings, {
            autoplay: false,
            arrows: true,
            dots: true,
            draggable: true,
            slide: '.item',
            swipeToSlide: true,
            appendArrows: $rotator.nextAll('.design-contents-rotator-arrows'),
            appendDots: $rotator.nextAll('.design-contents-rotator-dots'),
            responsive: [
              {
                breakpoint: LANDSCAPE_MOBILE_WIDTH,
                settings: {
                  adaptiveHeight: true
                }
              }
            ],
          }));
      });


      // Portfolio gallery
      // -----------------
      $('.portfolio-page-gallery-container .portfolio-page-gallery').slick($.extend({}, slickSettings, {
        fade: true,
        dots: true,
        draggable: true,
        slide: '.portfolio-gallery-item',
        swipeToSlide: true,
        appendArrows: '.portfolio-page-gallery-container .portfolio-gallery-arrows',
        appendDots: '.portfolio-page-gallery-container .portfolio-gallery-pager',
        customPaging: function(slider, index) { return $('<div style="background-image:url(\''.concat(slider.$slides.eq(index).data('pre'), '\')"/>')); }
      }));

      // Response rotator
      // ----------------
      $('.response-rotator').each(function() {
        var $rotator = $(this).children('.response-list');

        $rotator.slick($.extend({}, slickSettings, {
          autoplay: true,
          adaptiveHeight: IS_MOBILE_WIDTH(),
          draggable: true,
          appendArrows: $(this).children('.response-rotator-arrows'),
          slide: '.response-item',
          slidesToScroll: 1,
          slidesToShow: 3,
          swipeToSlide: true,
          responsive: [
            {
              breakpoint: DESKTOP_WIDE_WIDTH,
              settings: {
                autoplay: true,
                adaptiveHeight: false,
                slidesToShow: 2
              }
            },
            {
              breakpoint: LANDSCAPE_MOBILE_WIDTH,
              settings: {
                autoplay: false,
                adaptiveHeight: true,
                slidesToShow: 1
              }
            }
          ]
        }));
      });

      // Partners rotator
      // ----------------
      $('.partners-rotator').each(function() {
        $(this).children('.partners-list')
          .on('afterChange', function(e, slider) {
            slider.$slider.find('.gallery-item img').trigger('unveil.unveil');
          })
          .slick($.extend({}, slickSettings, {
            appendArrows: $(this).children('.partners-rotator-arrows'),
            draggable: true,
            slide: '.partners-item',
            slidesToScroll: 1,
            slidesToShow: 7,
            swipeToSlide: true,
            responsive: [
              {
                breakpoint: DESKTOP_WIDE_WIDTH,
                settings: {slidesToShow: 6}
              },
              {
                breakpoint: LANDSCAPE_MOBILE_WIDTH,
                settings: {slidesToShow: 3}
              },
              {
                breakpoint: PORTRAIT_MOBILE_WIDTH,
                settings: {slidesToShow: 2}
              },
              {
                breakpoint: SMALL_MOBILE_WIDTH,
                settings: {slidesToShow: 1}
              }
            ]
          }));
      });

      // Certificates rotator
      // --------------------
      $('.certs-rotator').each(function() {
        $(this).find('.gallery-block-container > div')
          .one('afterChange', function(e, slider) {
            slider.$slider.find('.gallery-item img').trigger('unveil.unveil');
          })
          .slick($.extend({}, slickSettings, {
            appendArrows: $(this).children('.certs-rotator-arrows'),
            draggable: true,
            slide: '.gallery-item',
            slidesToScroll: 1,
            slidesToShow: 6,
            swipeToSlide: true,
            responsive: [
              {
                breakpoint: LANDSCAPE_MOBILE_WIDTH,
                settings: {slidesToShow: 4}
              },
              {
                breakpoint: PORTRAIT_MOBILE_WIDTH,
                settings: {slidesToShow: 3}
              },
              {
                breakpoint: SMALL_MOBILE_WIDTH,
                settings: {slidesToShow: 2}
              }
            ]
          }));
      });

      // Price cards rotator
      // -------------------
      $('.price-vitrine-container > .list-view')
        .one('afterChange', function(e, slider) {
          slider.$slider.find('.list-view-item-image [data-lazy-src]').trigger('unveil.unveil');
        })
        .slick($.extend({}, slickSettings, {
          appendArrows: $('.price-vitrine-container'),
          draggable: true,
          slide: '.list-view-item',
          slidesToScroll: 1,
          slidesToShow: 6,
          swipeToSlide: true,
          responsive: [
            {
              breakpoint: 1200,
              settings: {slidesToShow: 5}
            },
            {
              breakpoint: LANDSCAPE_TABLET_WIDTH,
              settings: {slidesToShow: 4}
            },
            {
              breakpoint: LANDSCAPE_MOBILE_WIDTH,
              settings: {slidesToShow: 3}
            },
            {
              breakpoint: 640,
              settings: {slidesToShow: 2}
            },
            {
              breakpoint: 460,
              settings: {slidesToShow: 1}
            }
          ]
        }));

      // Calc form inputs slider
      // -----------------------
      $('.calc-form-container .step .field-wrapper.slider .slider-container').each(function() {
        var $slider = $(this);

        $slider.slider({
          classes: {
            'ui-slider': '',
            'ui-slider-handle': '',
            'ui-slider-range': ''
          },
          range: false,
          value: parseInt($slider.data('value')) || 30,
          step: parseInt($slider.data('step')) || 1,
          min: parseInt($slider.data('min')) || 30,
          max: parseInt($slider.data('max')) || 500,
          slide: function(e, ui) {
            $slider.siblings('.input-container:first').children().val(ui.value).trigger('change');
          }
        });
      });
    })

    // Move header menu items
    // ----------------------
    .on('moveHeaderMenuItems', function() {
      if( IS_LANDSCAPE_TABLET_WIDTH() ) {
        if( !$INFO_MENU.hasClass('is-moved') ) {
          $INFO_MENU.addClass('is-moved');

          $SERVICE_MENU.find('ul').removeAttr('style');
          $SERVICE_MENU.find('li').removeClass('is-hovered');

          //$INFO_MENU.children('.main-item').prependTo($SERVICE_MENU);
          $INFO_MENU.children('.info-item').appendTo($SERVICE_MENU);
        }
      }
      else {
        if( $INFO_MENU.hasClass('is-moved') ) {
          $INFO_MENU.removeClass('is-moved');

          //$SERVICE_MENU.children('.main-item').prependTo($INFO_MENU);
          $SERVICE_MENU.children('.info-item').appendTo($INFO_MENU);

          $SERVICE_MENU.find('ul').removeAttr('style');
          $SERVICE_MENU.find('li').removeClass('is-hovered');
        }
      }
    })

    // Header service menu events
    // ---------------------------------
    .on('headerServiceMenuEvents', function() {
      if( IS_LANDSCAPE_TABLET_WIDTH() ) {
        if( !$SERVICE_MENU.hasClass('is-mobile-events') ) {
          $SERVICE_MENU.addClass('is-mobile-events');

          $DOCUMENT
            // Close header menu
            // -----------------
            .on('click.closeHeaderMenu', function(e) {
              if( !($(e.target).closest('.header-menu-checkbox').length || $(e.target).closest('.header-menu-button').length || $(e.target).closest('.header-menu-container > .wrapper > ul').length) ) {
                $MENU_CHECKBOX.prop('checked', false).trigger('change');

                e.stopPropagation();
              }
            })

            // Header menu items
            .on('click.headerMenu', '.header-menu-container > .wrapper > ul li > div > div > a, .header-menu-container > .wrapper > ul li > div > div > span, .header-menu-container > .wrapper > ul li > div > .dropmarker', function(e) {
              e.preventDefault();

              var $self = $(this),
                  $parent = $self.closest('li'),
                  $elementsToClose = $parent.siblings('.with-dropdown:not(.icn-menu-service-group-null)').add($parent.siblings('.icn-menu-service-group-null').find('> ul > li.with-dropdown')),
                  isWithDropdown = $parent.hasClass('with-dropdown'),
                  isOnHover = $parent.hasClass('is-hovered');

              if( $parent.closest('ul').closest('.icn-menu-service-group-null').length )
                $elementsToClose = $elementsToClose.add($parent.closest('ul').closest('.icn-menu-service-group-null').siblings('.with-dropdown'));

              $elementsToClose.removeClass('is-hovered').children('ul').slideUp(300);

              if( isWithDropdown ) {
                if( isOnHover ) {
                  if( $self.prop("tagName").toUpperCase() === 'A' )
                    location.href = $self.prop('href');
                  else
                    $parent.removeClass('is-hovered').children('ul').slideUp(300);
                } else {
                  $parent.addClass('is-hovered').children('ul').slideDown(300);
                }
              } else {
                if( $self.prop("tagName").toUpperCase() === 'A' )
                  location.href = $self.prop('href');
              }
            });
        }
      }
      else {
        if( $SERVICE_MENU.hasClass('is-mobile-events') ) {
          $SERVICE_MENU.removeClass('is-mobile-events');

          $DOCUMENT
            .off('click.closeHeaderMenu')
            .off('click.headerMenu');
        }

        // Fix header submenu popup position
        // ---------------------------------
        var headerMenuWidth = $SERVICE_MENU.outerWidth();

        $SERVICE_MENU.children().each(function() {
          var $item = $(this),
              $submenu = $(this).children('ul');

          if( $submenu.length ) {
            $submenu.css('max-width', headerMenuWidth);

            if( $submenu.outerWidth() + $item.position().left > headerMenuWidth )
              $submenu.css('left', 0 - ($submenu.outerWidth() + $item.position().left - headerMenuWidth));
          }
        });
      }
    })

    // Scroll to
    // ---------
    .on('scrollTo', (e, $element, speed, offset) => {
      if ($element === undefined)
        $element = $(e.target);

      if (speed === undefined)
        speed = $element.data('scrollSpeed') || 150;

      if (offset === undefined)
        offset = $element.data('scrollOffset') || 0;

      $WINDOW.scrollTo($element, {axis: 'y', duration: speed, offset: parseInt(offset) - (IS_LANDSCAPE_TABLET_WIDTH() ? 0 : HEADER_HEIGHT)});
    })
    .on('click.jsScrollTo', '.js-scroll-to', e => {
      e.preventDefault();

      let $link = $(e.currentTarget),
          $element = $($link.attr('href').substring($link.attr('href').lastIndexOf('#'))),
          speed = $link.data('scrollSpeed') || 150,
          offset = $link.data('scrollOffset') || undefined;

      $DOCUMENT.trigger('scrollTo', [$element, speed, offset]);
    })

    // Tabs
    // ----
    .on('click.jsTabs', '.js-tabs a', function(e) {
      e.preventDefault();

      var $lnk = $(this);

      if( !$lnk.hasClass('active') ) {
        var $container = $lnk.parents('.js-tabs'),
            $destination = $($container.data('tabDest')),
            cssClass = $container.data('tabCssClass') || 'dn';

        $lnk.addClass('active').parent().siblings().children('a').removeClass('active');
        $destination.children().addClass(cssClass).eq($lnk.parent().index()).removeClass(cssClass);
        $destination.trigger('lookup.unveil');
      }
    })

    // Price groupped quick links
    // --------------------------
    .on('click.priceGrouppedQuickLinks', '.price-groupped-table-container .quick-link-list a', function(e) {
      e.preventDefault();

      var $link = $(this);

      if( !$link.parent().hasClass('active') ) {
        $link
          .parent().addClass('active')
          .siblings().removeClass('active')
          .first().parent().parent().addClass('selected')
          .parent().find('.price-table-container .price-table').addClass('selected')
          .find($link.attr('href').substr($link.attr('href').lastIndexOf('#')))
          .parent().addClass('active')
          .siblings().removeClass('active');
      }
    })

    // Design tariff table row popup notice
    .on('click.designTariffTableRowNotice', '.design-tariff-table tbody td:first-child > span', function() {
      if( VIEWPORT_WIDTH < 900 ) {
        $.fancybox.open({
          src: '<div class="pt3 pr20"><div id="design-tariff-content-fancybox">'.concat('<div class="subsubheader">', $(this).text(), '</div>', $(this).next().html(), '</div></div>'),
          type: 'html'
        });
      }
    })

    // Design tariff list button show
    .on('click.designTariffListButtonShow', '.design-tariff-list .mobile-show-button .button-style', function(e) {
      e.preventDefault();

      $(this).closest('.mobile-show-button').addClass('hide').siblings('.mobile-hide').addClass('visible');
      $DOCUMENT.trigger('scrollTo', [$(this).closest('.design-tariff-list')]);
    })

    // Show-hide block
    // ---------------
    .on('click.jsShowHide', '.js-show-hide-handle', function(e) {
      e.preventDefault();

      var $self = $(this),
          $destination = $($self.data('dest'));

      if( $self.hasClass('active') ) {
        $self.removeClass('active').text($self.data('text'));
        $destination.addClass('dn');
      }
      else {
        $self.addClass('active').text($self.data('textActive'));
        $destination.removeClass('dn');
      }
    })

    // Show header city chooser
    // ------------------------
    .on('click.showHeaderCityChooser', '.header-city-label', function(e) {
      e.preventDefault();

      $('.header-city-choose-container').toggleClass('is-opened');
    })

    // Hide header city chooser
    // ------------------------
    .on('click.hideHeaderCityChooser', function(e) {
      if( !($(e.target).closest('.header-city-label').length || $(e.target).closest('.header-city-choose-container').length) ) {
        $('.header-city-choose-container').removeClass('is-opened');

        e.stopPropagation();
      }
    })

    // Select header city
    // ------------------
    .on('click.selectHeaderCity', '.header-cities-container > div', function(e) {
      e.preventDefault();

      var $self = $(this);

      if( !$self.hasClass('active') ) {
        var cityId = $self.data('cityId'),
            cityKey = 'city'.concat(cityId);

        if( $self.hasClass('redirect') )
          location.href = $self.children('a').attr('href');
        else
          $.ajax({
            type: 'POST',
            url: '/ajax/set_active_city/',
            data: {
              cityId: cityId,
              getData: !cities[cityKey] ? 1 : 0
            },
            beforeSend: function() { $.mouseLoader(true); },
            dataType: 'json'
          })
            .done(function(cityData) {
              if( !cities[cityKey] )
                cities[cityKey] = cityData;

              $('.header-city-label > span').text($self.text());
              $self.addClass('active').siblings().removeClass('active');

              document.title = window.PAGE_TITLE.replace(/<span.+?class="(.*?\s+?)?js-city-name(.*?\s+?)?".*?>\s*?\S+?.*?<\/span>/gi, cities[cityKey]['name_replace']).stripTags().escapeHtml();
              document.querySelector('meta[name="title"]').setAttribute('content', document.title);

              $('.header-contacts').html(cities[cityKey]['header']);

              $('.footer-content .footer-contacts .footer-phones').replaceWith(cities[cityKey]['footer_phones']);

              $('.js-city-name').text(cities[cityKey]['name_replace']);
              $('.js-city-phone').text(cities[cityKey]['phone']).closest('a').attr('href', 'tel:'.concat(cities[cityKey]['phone_clear']));
              $('.js-city-phone-short').text(cities[cityKey]['phone_short']).closest('a').attr('href', 'tel:'.concat(cities[cityKey]['phone_clear']));

              $.mouseLoader(false);
            })
            .fail(function() {
              $.mouseLoader(false);
              alert('Во время загрузки данных возникла ошибка, попробуйте позже.');
            });
      }

      $('.header-city-choose-container').removeClass('is-opened');
    })

    // Video frame load
    // ----------------
    .on('click.videoFrame', '.video-frame > a', e => {
      e.preventDefault();

      let $self = $(e.currentTarget),
          $iframe = $self.next();

      $iframe.attr('src', () => $iframe.data('src'));

      if (($self.data('popup') !== undefined) || (IS_WIDTH_LESS_PORTRAIT_TABLET() && ($self.outerWidth() <= VIEWPORT_WIDTH / 2)))
        $.fancybox.open($iframe);
      else
        $iframe.parent().addClass('active');
    })

    // Calc form scripts
    // -----------------
    .on('click.calcFormRadioChange', '.calc-form-container .step .field-wrapper.radio input', function() {
      $(this).closest('.step').addClass('enabled');
    })
    .on('change.calcFormSliderChange', '.calc-form-container .step .field-wrapper.slider input', function(e) {
      e.preventDefault();

      var min = parseInt($(this).data('min')) || 30,
          max = parseInt($(this).data('max')) || 500,
          value = parseInt($(this).val()) || min;

      if( value < min )
        value = min;
      else if( value > max )
        value = max;

      $(this).val(value).closest('.input-container').siblings('.slider-container:first').slider('value', value);
    })
    .on('click.calcFormStepChange', '.calc-form-container:not(.calc-price-form) .step.enabled .form-buttons button', function(e) {
      e.preventDefault();

      var $step = $(this).closest('.step').removeClass('active'),
          index = 2+$step.closest('.calc-form-container').find('.step').index($step),
          $progress = $step.next().addClass('active').siblings('.calc-form-progress:first').children(),
          percent = (Math.ceil(index / $progress.data('steps') * 100 / 5) * 5)+'%';

      if( index == 2 )
        $step.closest('.calc-design-container, .calc-project-container').find('.calc-form-container > div:first-child').css('display', 'none');

      $progress.css('width', percent).children().text(percent);

      if( !$progress.closest('.form-popup-container').length )
        $DOCUMENT.trigger('scrollTo', [$progress]);
    })
    .on('submit.calcPriceSubmit', '#calc-price-form', function(e) {
      e.preventDefault();
    })
    .on('change.calcPriceChange', '#calc-price-form', function(e) {
      e.preventDefault();

      var $form = $(this),
          values = {},
          result;

      $form.serializeArray().forEach(function(item) { values[item.name] = item.value; });

      result = CALLBACKS.calcPriceCalculate(values);

      $form.next('.calc-price-form-result').children().html('Предварительная <wbr>стоимость <wbr>ремонта: '.concat('<wbr><span>', number_format(result.summ), '</span> руб.', result.discount));
    })

    // Specification forms radio uncheck
    .on('click.specsFormRadioUncheck', '.specification-form .form-row-radiolist input[type="radio"]', function() {
      var $self = $(this),
          $parent = $self.closest('.form-row-radiolist');

      if( ($parent.data('checked') === true) && ($self.data('checked') === true) ) {
        $parent.data('checked', false);
        $self.prop('checked', false).data('checked', false).trigger('input');
      }
      else {
        $parent.data('checked', true);
        $self.data('checked', true).parent().siblings('div').children('input[type="radio"]').data('checked', false);
      }
    })

    // Specification forms textarea auto-height
    .on('specsFormTextareaAutoHeight', function(e, $areas) {
      $areas.each(function(i, element) {
        var $area = $(element),
            $hidden = $area.siblings('div').children('div');

        $hidden.html($area.val().replace(/</g, '&lt;').replace(/>/g, '&gt;').split('\\n').map(function(line) { return '<div>'.concat(line.replace(/\\h{2}/g, ' &nbsp;'), '&nbsp;</div>'); }).join(''));
        $area.css('height', 15+$hidden.outerHeight());
      });
    })
    .on('keyup.specsFormTextareaKeyup cut paste', '.specification-form .form-row-textarea .form-field .input-container textarea', function(e) {
      var $area = $(this);

      if( e.type === 'cut' || e.type === 'paste' )
        setTimeout(function() { $DOCUMENT.trigger('specsFormTextareaAutoHeight', [$area]); }, 100);
      else
        $DOCUMENT.trigger('specsFormTextareaAutoHeight', [$area]);
    })
    .on('click.specsFormPrevButton', '.specification-form .form-groups + .form-buttons .button-style.prev', function(e) {
      e.preventDefault();

      var $group = $(this).closest('form').find('.form-groups .form-group.active');

      $group.removeClass('active').prev().addClass('active').parent().removeClass('last middle').addClass(function() {
        return ($group.index() == 1 ? 'first' : 'middle');
      });
      $DOCUMENT.trigger('scrollTo', [$group.prev()]);
    })
    .on('click.specsFormNextButton', '.specification-form .form-groups + .form-buttons .button-style.next', function(e) {
      e.preventDefault();

      var $form = $(this).closest('form'),
          $group = $form.find('.form-groups .form-group.active'),
          settings = $form.data('settings'),
          actionNextStep = function() {
            $group.removeClass('active').next().addClass('active').parent().removeClass('first middle').addClass(function() {
              return ($(this).children().length == ($group.index() + 2) ? 'last' : 'middle');
            });
            $DOCUMENT.trigger('scrollTo', [$group.next()]);
          };

      if (settings.timer !== undefined) {
        clearTimeout(settings.timer);
      }
      settings.submitting = true;
      if (settings.beforeValidate === undefined || settings.beforeValidate($form)) {
        $.mouseLoader(true);

        $.fn.yiiactiveform.validate(
          $form,
          function (response) {
            if( response.status == 'ok' )
              if( response.validateErrors !== undefined ) {
                var messages = $.parseJSON(response.validateErrors),
                    firstError;

                $.each(settings.attributes, function () {
                  if( $group.find('#' + this.errorID).length && $.fn.yiiactiveform.updateInput(this, messages, $form) && !firstError )
                    firstError = this;
                });

                if( firstError )
                  $DOCUMENT.trigger('scrollTo', [$.fn.yiiactiveform.getInputContainer(firstError, $form).closest('.form-row')]);
                else
                  actionNextStep();
              }
              else
                actionNextStep();

            settings.submitting = false;

            $.mouseLoader(false);
          },
          undefined,
          $form.data('stepValidationUrl')
        );
      } else {
        settings.submitting = false;
      }
    })
    .on('input.specsFormChange', '.specification-form', function(e) {
      var $form = $(this),
          inputType = e.target.type.toUpperCase();

      if (window.specificationForm_saveToSession)
        clearTimeout(window.specificationForm_saveToSession);

      window.specificationForm_saveToSession = setTimeout(function() {
        window.specificationForm_saveToSession = null;

        $.post({
          url: $form.data('saveToSessionUrl'),
          data: $form.serialize()
        });
      }, (['CHECKBOX', 'RADIO'].includes(inputType) ? 100 : 600));
    });


  // FancyBox
  // --------
  $.extend(true, $.fancybox.defaults, {
    autoFocus: false,
    loop: true,
    infobar: false,
    smallBtn: true,
    buttons: [
      false,
      false,
      false,
      'close'
    ],
    idleTime: false,
    transitionEffect: 'slide',
    hash: false,
    mobile: {
      clickSlide: 'close'
    },
    lang: 'ru',
    i18n: {
      ru: {
        CLOSE: 'Закрыть',
        NEXT: 'Следующий слайд',
        PREV: 'Предыдущий слайд',
        ERROR: 'Ошибка загрузки. <br/>Пожалуйста, попробуйте позже.',
        PLAY_START: 'Запустить показ слайдов',
        PLAY_STOP: 'Остановить показ слайдов',
        FULL_SCREEN: 'В полный экран',
        THUMBS: 'Миниатюры',
        DOWNLOAD: 'Скачать',
        SHARE: 'Поделиться',
        ZOOM: 'Масштаб'
      }
    },

    beforeLoad: (widget, current) => {
      switch (current.type) {
        case 'image':
          current.opts.protect = true;
          break;

        case 'inline':
          current.opts.touch = false;
          break;
      }
    },
    afterLoad: (widget, current) => {
      if (current.contentType === 'html' && !IS_MOBILE && !IS_MOBILE_WIDTH())
        current.$content.find('.js-autofocus-inp').trigger('focus');
    }
  });


  // News filter
  // -----------
  if( $('.news-groups-links').length ) {
    var $newsGrid = $('.news-groups-links + .news-block-container .news-items')
          /*.on('layoutComplete', function(e, items) {
            console.log('Isotope layout completed on ' + items.length + ' items');
          })*/
          .isotope({
            itemSelector: '.list-view-item',
            layoutMode: 'fitRows',
            percentPosition: true,
            transitionDuration: '0.5s',
            containerStyle: null
          }),
        $links = $('.news-groups-links a');

    $links.on('click.newsFilter', function(e) {
      e.preventDefault();

      location.hash = encodeURIComponent( $(this).data('filterValue') );
    });

    $WINDOW.on('hashchange', function(e) {
      var filterValue = decodeURIComponent( location.hash.slice(1) );

      if( filterValue.length ) {
        $links.removeClass('active').filter('[data-filter-value="'.concat(filterValue, '"]')).addClass('active');

        if( filterValue === 'all' ) {
          $newsGrid.isotope({ filter: '*'});
        } else {
          $newsGrid.isotope({ filter: '[data-filter-group="'.concat(filterValue, '"]')});
        }
      }
    });
  }
})();